import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const StyledAwardsBulletList = styled.div`
  margin: 120px 0 5em;
  .center {
    margin-bottom: 1em;
    text-align: center;
    @media (min-width: ${breakpoints.tablet}) {
      .center {
        margin: 2em 0 1.5em
      }
    }
  }
  .awards-container {
    @media (min-width: ${breakpoints.tablet}) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .award {
      max-width: 210px;
      padding: 10px;
      text-align: center;
      margin: 0 auto;
      a {
        color: black;
      }
      .img-cont {
        max-width: 200px;
        margin: 0 auto;
        padding: 0 10px;
        img {
          min-width: 140px;
          width: 100%;
        }
      }
    }
  }
  .abl-headline-container {
    margin-bottom: 90px;
    .abl-group-headline {
      margin-bottom: 1rem;
    }
  }
  
  .bullet-container {
    margin-bottom: 3rem;
    a {
      color: ${colors.primaryGray};
    }
  }
  .bullet-section {
    margin-top: 120px;
    @media (min-width: ${breakpoints.tablet}) {
      h2 {
        margin-bottom: 1em;
      }
      .bullet-container {
        margin-bottom: 120px;
        ul {
          width: 33%;
        }
      }
    }
  }
`;

const AwardsBulletList = ({ data }) => {
  return (<>
    {data.map((group, index) => {
      const HeadlineGroup = () => !group.abl_headline_group ? null : (
        <div className="abl-headline-container centered">
          <GroupHeadline />
          <GroupCopy />
        </div>);
      const GroupHeadline = () => !group.abl_headline_group.abl_headline ? null :
        (<h2 className="abl-group-headline">{group.abl_headline_group.abl_headline}</h2>);
      const GroupCopy = () => !group.abl_headline_group.abl_copy ? null :
        (<div className="copy">{ReactHtmlParser(group.abl_headline_group.abl_copy)}</div>);

      const AwardsContainer = () => !group.abl_awards ? null : (
        <div className="awards-container">
          {group.abl_awards.map(item => {
            const AwardsHeadline = () => !item.abl_award_headline ? null : (<h4>{item.abl_award_headline}</h4>);
            const AwardsCopy = () => !item.abl_award_copy ? null : <>{ReactHtmlParser(item.abl_award_copy)}</>;
            const AwardsLink = () => !item.abl_award_link ? null : (
              <a className="award-link" href={item.abl_award_link}>
                <AwardsImage/>
                <AwardsHeadline/>
                </a>);
            const AwardsImage = () => !item.abl_award_image ? null : !item.abl_award_image.source_url ? null : (
              <div className="img-cont"><img src={item.abl_award_image.source_url}/></div>);
              return (
                !item ? null : item.abl_award_link ? (
                  <div className="award">
                    <AwardsLink/>
                    <AwardsCopy/>
                  </div>
                ) : (
                  <div className="award">
                    <AwardsImage/>
                    <AwardsHeadline/>
                    <AwardsCopy/>
                  </div>
                )
              );
          })}
        </div>
        );
      const BulletList = () => {
        let lists = Object.entries(group.abl_list_group);
        return (
          lists.map(column => (
            column.map(list => typeof(list) !== 'object' ? null : (
              <ul>
                {list.map(item => {
                  const ListItem = () => !item.abl_list_item ? null : (<li className="copy">{item.abl_list_item}</li>);
                  return (
                    !item.abl_list_item_url ? (<ListItem/>) : (
                      <Link to={item.abl_list_item_url}>
                        <ListItem />
                      </Link>))})}
              </ul>)
        ))))}

      const BulletListContainer = () => !group.abl_show_bullet_list ? null : (
        <div className="bullet-section">
          <h2>{group.abl_list_group.abl_bullet_list_headline}</h2>
          <div className="bullet-container">
            <BulletList />
          </div>
        </div>
      );

      return (
      <StyledAwardsBulletList data-abl-group={`abl-group-${index}`}>
        <HeadlineGroup />        
        <AwardsContainer />
        <BulletListContainer />
      </StyledAwardsBulletList>
      );
    })}
    </>
  )

};

export default AwardsBulletList;
