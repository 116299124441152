import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
// import StickyBox from "react-sticky-box";

//SECTIONS
import SEO from "../../components/SEO";
import ImageCircle from "../../components/ImageCircle";
import DefaultSharingImage from "../../static/ansira-logo-sharing.png";
import GlobalOurClients from '../../components/GlobalOurClients';
import ContactBanner from '../../components/ContactBanner';
import ButtonGrey from "../../components/ButtonGrey";
import AwardsBulletList from "../../components/AwardsBulletList";

const StyledContact = styled.main`
  margin: 75px auto 0;
  @media (min-width: ${breakpoints.tablet}) {
    margin: 60px auto 0;
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin: 90px auto 0;
  }
  max-width: 1500px;
  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: ${breakpoints.tablet})  {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    @media (min-width: ${breakpoints.desktop})  {
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }
    h2 {
      margin: 60px auto;
    }
    &.awards-container {
      .award {
        max-width: 210px;
        padding: 10px;
        text-align: center;
        margin: 0 auto;
        a {
          color: black;
        }
        .img-cont {
          max-width: 200px;
          margin: 0 auto;
          padding: 0 10px;
          img {
            min-width: 140px;
            width: 100%;
          }
        }
      }
      @media (min-width: ${breakpoints.tablet})  {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
  .blog-item {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 2px solid ${colors.secondaryGray};
  }
  .image-box {
    background-color: ${colors.white};
    width: 100%;
    overflow: hidden; /* need overflow: hidden for ie  */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    img {
      margin-bottom: 0;
    }
  }
  .img-circle {
    max-height: 400px;
    max-width: 400px;
  }
  .post-excerpt {
    flex: 1 1 auto; /* must use this shorthand for IE */
  }
  .post-link {
    height: auto;
  }
  .centered {
    text-align: center;
  }
  .hero-container h3 {
    color: #ec2d35;
    line-height: 1.1;
    font-size: 28px;
    font-weight:400;
    margin: 0 0 0 .1em;
    @media (min-width: ${breakpoints.tablet}) {
      font-size: 30px;
    }
    @media (min-width: ${breakpoints.desktop}) {
      font-size: 35px;
    }
  }
  .hero-container h1 {
    color: #000;
    font-weight:700;
    line-height: 1.1;
    @media (min-width: ${breakpoints.tablet}) {
      font-size: 70px;
    }
    @media (min-width: ${breakpoints.desktop}) {
      font-size: 100px;
      line-height: .9;
      margin-top: 4px;
    }
  }
  h1 {
    font-size:50px;
    line-height:45px;
  }
  .img-section {
    padding: 0px 0 40px;
  }
  blockquote .quote {
    margin-top: 60px;
    h3 {
      font-family: 'daytona-bold';
      text-transform: none;
    }
  }
  .post-details {
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1em;
    background-color: ${colors.white};
  }
  .blog-item ul {
    margin:0;
  }
  .blog-item li {
    padding:1rem;
    border-bottom: 2px solid ${colors.secondaryGray};
  }
  .blog-item li:last-of-type {
    border-bottom: none;
  }
  .blog-item ul h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
  }
  .blog-item ul a {
    display:block;
    text-align: center;
  }
  .by-the-numbers {
    text-align: center;
    .numbers-headline {
      margin-bottom: 60px;
    }
    .numbers-items-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .numbers-items {
        width: 50%;
        margin-bottom: 60px;
        @media (min-width: ${breakpoints.tabletMax}) {
          width: 20%;
        }
        .numbers-number {
          color: #dddddd;
          font-size: 32px;
          font-weight: bold;
          line-height: 1.15em;
          max-width: 220px;
          margin: 0 auto 15px;
          @media (min-width: ${breakpoints.mobileMax}) {
            font-size: 50px;
          }
        }
        .numbers-item {
          color: ${colors.black};
          font-size: 22px;
          font-weight: bold;
          line-height: 1.25em;
          max-width: 220px;
          margin: 0 auto;
        }
      }
    }
  }
`;

const StyledClients = styled(GlobalOurClients)`
  padding-top: 0;
  h2 {
    display: none;
  }
`;

const Landing = ({ data }) => {
  const landing_page = data.wordpressPage;
  let additionalItemsArray = [];
  const additionalItems = landing_page.acf.additional_item_title;

  additionalItems.forEach(item => {
    additionalItemsArray.push({
      title: item.item_title,
      cta: item.item_cta,
      ctaURL: item.item_cta_url
    });
  });

  return (
    <StyledContact className="animate">
      <SEO
        isBlogPost={false}
        postData={landing_page}
        postImage={DefaultSharingImage}
      />
      <div className="site-content">
{/* HERO */}
        <section className="hero-container section-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-7">
                <h3>{ReactHtmlParser(landing_page.acf.landing_small_title)}</h3>
                <h1>{ReactHtmlParser(landing_page.acf.landing_large_title)}</h1>
                <div className="copy">
                  {ReactHtmlParser(landing_page.acf.landing_intro_copy)}
                </div>
            </div>
            <div className="d-none d-lg-block col-md-6 col-xl-5 img-section left">
              <ImageCircle
                img={landing_page.acf.landing_intro_image.source_url}
              />
            </div>
          </div>
        </section>

{/* BY THE NUMBERS */}
        {landing_page.acf.numbers_headline !== '' || landing_page.acf.numbers_items !== '' ? (
          <section className="section-container">
            <div className="by-the-numbers">
              <h2 className="numbers-headline">{landing_page.acf.numbers_headline}</h2>
              <div className="numbers-items-wrapper">
                {landing_page.acf.numbers_items.map(item => {
                  return (
                    <div className="numbers-items">
                      <p className="numbers-number">{item.numbers_number}</p>
                      <p className="numbers-item">{item.numbers_item}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}

{/* CLIENT LOGOS */}
        <section className="section-container">
          <StyledClients
            headline={landing_page.acf.client_logo_headline}
            logos={landing_page.acf.client_logos}
            quote={ReactHtmlParser(landing_page.acf.quote)}
          />
        </section>

{/* AWARDS & BULLET LIST */}
        <section className="section-container">
          <AwardsBulletList data={landing_page.acf.abl_group} />
        </section>


{/* LANDING ARTICLES */}
      <section class="section-container">
          <div className="row">
            <div className="col-12">
              <h2 className="centered">{ReactHtmlParser(landing_page.acf.landing_article_main_headline)}</h2>
            </div>
          </div>

          <div className="post-area page-container">
            <div className="posts-display row">
              <div className="blog-item col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <React.Fragment>
                  <div className="image-box">
                    <img src={landing_page.acf.landing_article_image.source_url} alt="Article one image" />
                  </div>
                  <div className="post-details">
                    <h4>
                      <Link to={ReactHtmlParser(landing_page.acf.landing_article_cta_url)} className="post-title">
                        {ReactHtmlParser(landing_page.acf.landing_article_title)}
                      </Link>
                    </h4>
                    <div className="post-excerpt">
                      {ReactHtmlParser(landing_page.acf.landing_article_copy)}
                    </div>
                    <div className="post-link">
                      <ButtonGrey
                        textLabel={ReactHtmlParser(landing_page.acf.landing_article_cta)}
                        pageLink={ReactHtmlParser(landing_page.acf.landing_article_cta_url)}
                        className="whiteCTA"
                      />
                    </div>
                  </div>
                </React.Fragment>
              </div>

              <div className="blog-item col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <React.Fragment>
                  <div className="image-box">
                    <img src={landing_page.acf.landing_article_image_two.source_url} alt="Article one image" />
                  </div>
                  <div className="post-details">
                    <h4>
                      <Link to={ReactHtmlParser(landing_page.acf.landing_article_cta_url_two)} className="post-title">
                        {ReactHtmlParser(landing_page.acf.landing_article_title_two)}
                      </Link>
                    </h4>
                    <div className="post-excerpt">
                      {ReactHtmlParser(landing_page.acf.landing_article_copy_two)}
                    </div>
                    <div className="post-link">
                      <ButtonGrey
                        textLabel={ReactHtmlParser(landing_page.acf.landing_article_cta_two)}
                        pageLink={ReactHtmlParser(landing_page.acf.landing_article_cta_url_two)}
                        className="whiteCTA"
                      />
                    </div>
                  </div>
                </React.Fragment>
                </div>

                <div className="blog-item col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <ul className="list-unstyled">
                    {additionalItemsArray.map((item) => {
                      return <li className="list-item"><h4>{item.title}</h4><ButtonGrey textLabel={item.cta} externalLink={item.ctaURL} className="whiteCTA" /></li>;
                    })}
                  </ul>
                </div>
              </div>
          </div>
        </section>

{/* CONTACT BANNER */}
        <section className="centered">
          <ContactBanner
            headline={landing_page.acf.landing_contact_header}
            cta={landing_page.acf.landing_contact_cta}
            url={landing_page.acf.landing_contact_url}
          />
      </section>
      </div>

    </StyledContact>
  );
};

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "raving-fans" }) {
      acf {
        seo_title
        seo_canonical
        seo_description
        landing_small_title
        landing_large_title
        landing_intro_copy
        landing_article_main_headline
        landing_article_title
        landing_article_title_two
        landing_article_copy
        landing_article_copy_two
        landing_article_cta
        landing_article_cta_two
        landing_article_cta_url
        landing_article_cta_url_two
        additional_item_title {
          item_title
          item_cta
          item_cta_url
        }
        landing_article_image {
          title
          source_url
        }
        landing_article_image_two {
          title
          source_url
        }
        landing_intro_image {
          title
          source_url
        }
        landing_contact_header
        landing_contact_cta
        landing_contact_url
        numbers_headline
        numbers_items {
          numbers_item
          numbers_number
        }
        client_logo_headline
        client_logos {
          source_url
        }
        quote
        abl_group {
          abl_headline_group {
            abl_headline
            abl_copy
          }
          abl_awards {
            abl_award_headline
            abl_award_copy
            abl_award_link
            abl_award_image {
              source_url
            }
          }
          abl_show_bullet_list
          abl_list_group {
            abl_bullet_list_headline
            abl_bullet_list_1 {
              abl_list_item
              abl_list_item_url
            }
            abl_bullet_list_2 {
              abl_list_item
              abl_list_item_url
            }
            abl_bullet_list_3 {
              abl_list_item
              abl_list_item_url
            }
          }
        }
      }
      
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default Landing;
